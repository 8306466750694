@import "var.conf";
@import "base.css";
@import "common.css";
/*---------------------------------
Page CSS 2018.05
CSS bit-naka Document 2019.10
Last Update 2018.05 担当者名
---------------------------------*/
  /*
  トップページ
  -------------------------------------*/
  #Page.pageIndex {
/*　TOP共通　*/
    #Main {
      h3 {
        text-align: center;
      }
      .contBox {
        .linkArea {
          display: block;
          position:relative;
          cursor: pointer;
          img:nth-of-type(1) {
            width: 100%;
          }
          h3 {
            position: absolute;
            top:0px;
            bottom: 0px;
            left: 0px;
            right:0px;
            margin: auto;
            width: 178px;
            height: 167px;
            font-size: 0;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: 178px auto;
            span {
              display: inline-block;
              position: relative;
              padding-right: 15px;
              width: 120px;
              height: 40px;
              background-color: #ffa000;
              color: #fff;
              font-size: 14px;
              line-height: 40px;
              border-radius: 20px;
              b {
                display: none;
              }
              &:before {
                content: "詳しく見る";
              }
              &:after {
                content: "";
                position: absolute;
                top: 0;
                right: 15px;
                bottom: 0;
                margin: auto;
                width: 15px;
                height: 15px;
                background-repeat: no-repeat;
                background-image: url(../img/contents/top/icon_open.png);
                background-size: 15px auto;
              }
            }
          }
          &.active {
            h3 {
              span {
                opacity: 0.7;
                &:before {
                  content: "閉じる";
                }
                &:after {
                  background-image: url(../img/contents/top/icon_close.png);
                }
              }
            }
          }
        }
        .linkBtn {
          text-align: center;
          a {
            display: inline-block;
            position: relative;
            width: 180px;
            height: 40px;
            border-radius: 20px;
            background-color: #ffa000;
            line-height: 41px;
            font-size: 14px;
            letter-spacing: 0.15em;
            color: #fff;
            text-decoration: none;
            &:after {
              content: "";
              position: absolute;
              top: 0;
              right: 15px;
              bottom: 0;
              margin: auto;
              width: 8px;
              height: 12px;
              background-repeat: no-repeat;
              background-image: url(../img/contents/arrow_01.png);
              background-size: 8px auto;
            }
          }
        }
      }
      .contBorder {
        padding-top: 5px;
        background-image: url(../img/contents/border.png);
        background-repeat: repeat-x;
        background-size: auto 5px;
        background-position: left top;
      }
    }

/* メインイメージ*/
    #MainImg {
      padding-top: 3px;
      ul.slider {
        position: relative;
        li {
          opacity: 0;
          position: absolute;
          left: 0;
          top: 0;
          z-index: 10;
          img {
            width: 100%;
          }
          &:nth-of-type(1){
          animation: anime 12000ms 0s infinite;
          }

          &:nth-of-type(2){
          animation: anime02 12000ms 0s infinite;
          animation-delay: 4000ms;
          }
          &:nth-of-type(3) {
          animation: anime03 12000ms 0s infinite;
          animation-delay: 8000ms;
          }
        }
      }
        img.imageArea {
          opacity: 0;
        }
    }

@keyframes anime {
  0% {
        opacity: 0;
    }
    17% {
        opacity: 1;
    }
    33% {
        opacity: 1;
    }
    50% {
        opacity: 0;
         z-index:9;
    }
    100% { opacity: 0 }
}

@keyframes anime02 {
  0% {
        opacity: 0;
    }
    17% {
        opacity: 1;
    }
    33% {
        opacity: 1;
    }
    50% {
        opacity: 0;
         z-index:9;
    }
    100% { opacity: 0 }
}

@keyframes anime03 {
  0% {
        opacity: 0;
    }
    17% {
        opacity: 1;
    }
    33% {
        opacity: 1;
    }
    50% {
        opacity: 0;
         z-index:9;
    }
    100% { opacity: 0 }
}

/*　個別　*/
    #Main {
      #ContBox01 {
        padding-top: 30px;
        padding-bottom: 58px;
        background-image: url(../img/contents/top/wall_baby.png), url(../img/contents/border.png),url(../img/contents/wall_dot.png);
        background-repeat: no-repeat, repeat-x, repeat;
        background-size: 102px auto, auto 5px, 45px auto;
        background-position: right bottom, left bottom,left top;
        background-color: #fff7db;
        h2 {
          width: 234px;
          height: 155px;
          margin: 0 auto;
          margin-bottom: 24px;
          background-image: url(../img/contents/top/title01.png);
          background-position: center top;
          background-repeat: no-repeat;
          background-size: 234px auto;
          text-indent: -9999px;
        }
        ul {
          margin-bottom: 15px;
          li {
            position: relative;
            margin-bottom: 5px;
            padding-left: 26px;
            font-weight: bold;
            color: #f55c70;
            &:before {
              content: "";
              position: absolute;
              top:7px;
              left:2px;
              width: 15px;
              height: 12px;
              background-repeat: no-repeat;
              background-image: url(../img/contents/arrow_07.png);
              background-size: 15px auto;
            }
          }
        }
        #ContBox02up {
          font-weight: bold;
        }
      }
      #ContBox02 {
        margin-top: -70px;
        padding-top: 70px;
        h3 {
          padding-top: 100px;
          background-image: url(../img/contents/top/cont02_title.png);
        }
        .icon {
          position: absolute;
          left: 0;
          bottom: 0;
          width:94px;
          height: 110px;
        }
        #ContSubBox01 {
          padding-top: 20px;
          .imgArea {
            margin-bottom: 10px;
            .imgBox {
              padding-top: 8px;
              float: left;
              width: 42%;
            img {
              width: 100%;
              border-radius: 10px;
            }
          }
            &:after {
              content: "";
              display: block;
              clear: both;
            }
            .textBox {
              position: relative;
              float: right;
              width: 58%;
              background-image: url(../img/contents/top/cont02_img02.png);
              background-repeat: no-repeat;
              background-size: auto 100%;
              background-position: right bottom;
              .name {
                display: none;
              }
              p {
                position: absolute;
                margin-bottom: -6px;
                left: 11px;
                bottom: 0;
                font-size: 14px;
                line-height: 1.8;
              }
            }
          }
          ul {
            margin-bottom: 5px;
            li {
              position: relative;
              padding-left: 15px;
              font-size: 14px;
              line-height: 1.75;
              color: #888;
              &:before {
                content: "";
                position: absolute;
                top:7px;
                left:0px;
                width: 10px;
                height: 10px;
                background-repeat: no-repeat;
                background-image: url(../img/contents/arrow_08.png);
                background-size: 10px auto;
              }
            }
          }
        }
        #SubBox {
          width: 100%;
          margin-bottom: 45px;
          padding-top: 29px;
          background-image: url(../img/contents/top/cont02_wall01.png);
          background-repeat: no-repeat;
          background-size: 25px;
          background-position: 36px top;
          h4 {
            margin-bottom: 20px;
            text-align: center;
            img {
              width: 140px;
            }
          }
          #InnerSubBox {
            padding-top: 25px;
            padding-bottom: 85px;
            width: 100%;
            border-radius: 10px;
            background-image: url(../img/contents/top/cont02_wall03.png),url(../img/contents/wall_02.png);
            background-repeat: no-repeat, repeat;
            background-size: 306px auto,345px auto;
            background-position: right bottom, left top;
            p {
              margin-left: 20px;
              margin-right: 20px;
            }
          }
        }
      }
      #ContBox03 {
        h3 {
          padding-top: 86px;
          background-image: url(../img/contents/top/cont03_title.png);
        }
        .icon {
          position: absolute;
          right: 15px;
          bottom: 15px;
          width:60px;
          height: 60px;
        }
        #ContSubBox02 {
          padding-top: 30px;
          .imgBox {
            p {
              padding-top: 10px;
            }
            img {
              float: right;
              width: 35%;
              margin-left: 15px;
              border-radius: 50%;
            }
            &:after {
              content: "";
              display: block;
              clear: both;
            }
          }
        p {
          &.notes {
            margin-bottom:40px;
            font-size: 14px;
            color: #888;
          }
        }
        }
      }
      #ContBox04 {
        h3 {
          padding-top: 100px;
          background-image: url(../img/contents/top/cont04_title.png);
        }
        #ContSubBox03 {
          padding-bottom: 20px;
          background-image: url(../img/contents/top/cont04_wall.png);
          background-size: 232px auto;
          background-position: center top;
          background-repeat: no-repeat;
          .subBox01 {
            padding-top: 56px;
          }
          h4 {
            text-align: center;
            img {
              width: 232px;
            }
          }
          img {
            margin-top: 10px;
            margin-bottom: 25px;
            width: 100%;
            border-radius: 10px;
          }
        }
      }
      #ContBox05 {
        padding-top: 50px;
        padding-bottom: 57px;
        h3 {
          margin-bottom: 40px;
        }
        p {
          &.notes {
            margin-bottom:20px;
            font-size: 14px;
            color: #888;
          }
        }
        #MapBox {
          margin-top: 30px;
          padding-top: 24px;
          background-image: url(../img/contents/top/cont05_img01.png);
          background-repeat: no-repeat;
          background-size: 68px auto;
          background-position: right top;
          .mapArea {
            width: 100%;
            height: 200px;
            margin-bottom: 10px;
            border-radius: 10px;
            -webkit-border-radius: 10px;
            overflow: hidden;
            position: relative;
          }
          iframe {
            margin-bottom: 5px;
            width: 100%;
            height: 200px;
          }
        }
      }
      #ContBox06 {
        padding-top: 60px;
        padding-bottom: 42px;
        background-image: url(../img/contents/bg_cloud.png);
        background-repeat: repeat-x;
        background-size: auto 45px;
        background-position: center top;
        background-color:#fff7db;
        h3 {
          margin-bottom: 27px;
        }
        ul.blogList {
          margin-bottom: 30px;
          li {
            span {
              background-color: #69afe9;
            }
          }
        }
      }
    }
    #ContBox07 {
        padding-top: 60px;
        padding-bottom: 56px;
        background-image: url(../img/contents/bg_cloud2.png);
        background-repeat: repeat-x;
        background-size: auto 45px;
        background-position: center top;
        h3 {
          margin-bottom: 27px;
        }
        ul.blogList {
          margin-bottom: 30px;
          li {
            a {
              text-decoration: none;
              dl {
                font-size: 0;
                dt {
                  display: inline-block;
                }
                dd.cate {
                  display: inline-block;
                }
              }
            }
            span {
              background-color: #bcd418;
            }
            b {
              margin-right: 10px;
              font-family: 'Abel', sans-serif;
              font-weight: 400;
              font-size: 14px;
              letter-spacing: 0.1em;
              color: #888;
            }
            p {
              margin-top: 0;
              text-decoration: underline;
            }
          }
        }
    }
  }


@media print, screen and (min-width: 768px) {
/*##### PC #####*/
  #Page.pageIndex {
    #MainImg {
      position: relative;
      padding-top: 0;
      height: 571px;
      ul.slider {
        margin: 0 auto;
        height: 571px;
        width: 100%;
        li {
          width: 100%;
          height: 571px;
          img {
            display: none;
          }
          background-repeat: no-repeat;
          background-position: center center;
          background-size: auto 571px;
          &:nth-of-type(1) {
            background-image: url(../img/contents/top/mainimage01_pc.jpg);
          }
          &:nth-of-type(2) {
            background-image: url(../img/contents/top/mainimage02_pc.jpg);
          }
          &:nth-of-type(3) {
            background-image: url(../img/contents/top/mainimage03_pc.jpg);
          }
        }
      }
      #MenuPc {
        position: absolute;
        width: 1000px;
        height: 120px;
        top: 421px;
        left: 0;
        right: 0;
        margin: 0 auto;
        background-color: #fff;
        border: #ffa000 solid 2px;
        border-radius: 15px;
        z-index: 12;
        ul {
          font-size: 0;
          li {
            display: inline-block;
            width: 248px;
            text-align: center;
            background-image: url(../img/header/line_dot.png);
            background-size:4px auto;
            background-repeat: no-repeat;
            background-position: left center;
            font-size: 16px;
            a {
              display: block;
              height: 116px;
              color: #333;
              text-decoration: none;
              padding-top:72px;
              &:hover {
                color: #ffa000;
                transition: 0.3s;
              }
            }
             &:nth-of-type(1) {
              width: 247px;
              background: none;
              a {
                background-image: url(../img/header/icon_img01.png);
                background-size: 50px auto;
                background-repeat: no-repeat;
                background-position: center 23px;
              }
            }
             &:nth-of-type(2) {
              width: 249px;
              a {
                background-image: url(../img/header/icon_img02.png);
                background-size: 46px auto;
                background-repeat: no-repeat;
                background-position: center 21px;
              }
            }
             &:nth-of-type(3) {
              width: 249px;
              a {
                background-image: url(../img/header/icon_img03.png);
                background-size: 30px auto;
                background-repeat: no-repeat;
                background-position: center 15px;
                letter-spacing: 0;
              }
            }
             &:nth-of-type(4) {
              a {
                background-image: url(../img/header/icon_img04.png);
                background-size: 47px auto;
                background-repeat: no-repeat;
                background-position: center 29px;
              }
            }
          }
        }
      }
    }
    .showBox {
      display: block;
    }
/*　TOP共通　*/
    #Main {
      .contBox {
        .linkArea {
          margin-bottom: 30px;
          height: 300px;
          overflow: hidden;
          background-repeat: no-repeat;
          background-position: center center;
          background-size: cover;
          cursor:default;
          img {
            display: none;
          }
          h3 {
            width: 226px;
            height: 232px;
            background-size: 226px auto;
            background-position: center bottom;
            span {
              display: none;
            }
          }
        }
      }
/*　個別　*/
      #ContBox01 {
        padding-top: 60px;
        padding-bottom: 68px;
        background-repeat: no-repeat, repeat-x, repeat;
        background-position: calc(50% + 590px) bottom, left bottom,left top;
        background-image: url(../img/contents/top/wall_baby.png), url(../img/contents/border.png), url(../img/contents/wall_dot.png);
        background-color: #fff7db;
        background-size: 102px auto, auto 5px, 45px auto;
        h2 {
          width: 714px;
          height: 160px;
          margin-bottom: 20px;
          background-image: url(../img/contents/top/title01_pc.png);
          background-size: 714px auto;
        }
        p {
          text-align: center;
          &#ContBox02up {
            font-size: 20px;
            letter-spacing: 0.04em;
          }
        }
        ul {
          text-align: center;
          padding: 0 40px;
          margin-bottom: 8px;
          li {
            display: inline-block;
            margin-right: 28px;
            margin-bottom: 10px;
            letter-spacing: 0;
            &:nth-of-type(2),
            &:nth-of-type(5) {
              margin-right: 0;
            }
          }
        }
      }
      #ContBox02 {
        .linkArea {
          background-image: url(../img/contents/top/cont02_photo01_pc.jpg);
          margin-bottom: 30px;
          h3 {
            background-image: url(../img/contents/top/cont02_title_pc.png);
          }
          .icon {
            display: block;
            left: 50%;
            margin-left: -465px;
            width: 130px;
            height: 152px;
          }
        }
        #ContSubBox01 {
          height: 690px;
          background-position: 333px 150px;
          background-image: url(../img/contents/top/cont02_wall01_pc.png);
          background-size: 50px;
          background-repeat: no-repeat;
          .imgArea {
            margin-bottom: 21px;
            .imgBox {
              padding-top: 13px;
              img {
                width: 140px;
                height: 200px;
              }
            }
            .textBox {
              width: 190px;
              background-size: 190px auto;
              background-position: left top;
              p {
                left:0;
              }
            }
          }
        }
        #CareerBox {
          float: left;
          width: 345px;
        }
        #SubBox {
          float: right;
          width: 646px;
          padding-left: 16px;
          padding-top: 10px;
          margin-bottom: 0;
          background:none;
          #InnerSubBox {
          width: 630px;
          padding-top: 47px;
          padding-bottom: 65px;
          border-radius: 20px;
          background-size: 306px auto,690px auto;
          h4 {
            img {
              width: 198px;
            }
            margin-bottom: 35px;
          }
          p {
            margin: 0 30px 20px 30px;
            line-height: 1.8;
          }
          }
        }
      }
      #ContBox03 {
        padding-bottom: 87px;
        .linkArea {
          background-image: url(../img/contents/top/cont03_photo01_pc.jpg);
          h3 {
            background-image: url(../img/contents/top/cont03_title_pc.png);
          }
          img.icon {
            display: block;
            bottom:30px;
            right: 50%;
            margin-right: -500px;
            width: 120px;
            height: 120px;
          }
        }
        #ContSubBox02 {
          position: relative;
          padding-top: 22px;
          p {
            width: 720px;
            &.notes {
              margin-bottom: 0;
              a {
                &:hover {
                  text-decoration: none;
                }
              }
            }
          }
          .imgBox {
            p {
              padding-top: 0;
              margin-bottom: 15px;
              line-height: 2;
            }
            img {
              position: absolute;
              top:0;
              right: 0;
              width: 260px;
              height: 260px;
            }
          }
        }
      }
      #ContBox04 {
        padding-bottom: 0px;
        .linkArea {
          margin-bottom: 17px;
          background-image: url(../img/contents/top/cont04_photo01_pc.jpg);
          h3 {
            background-image: url(../img/contents/top/cont04_title_pc.png);
          }
        }
        #ContSubBox03 {
          height: 480px;
          background-position: 39px top;
          .subBox01 {
            float: left;
            width: 310px;
            padding-top: 122px;
            h4 {
              margin-bottom: 5px;
              img {
                width: 280px;
              }
            }
            img {
              margin-top: 0;
            }
          }
          .subBox02 {
            float: right;
            width: 630px;
            padding-top: 36px;
          }
        }
      }
      #ContBox05 {
        padding-top: 75px;
        padding-bottom: 45px;
        h3 {
          margin-bottom: 35px;
          img {
            width: 272px;
          }
        }
        #ContSubBox04 {
          float: left;
          width: 470px;
          padding-top: 20px;
          h4 {
            margin-bottom: 20px;
            text-align: center;
          }
          table {
            margin-bottom: 18px;
          }
          p.notes {
            margin-bottom: 21px;
          }
        }
        #ContSubBox05 {
          float: right;
          width: 470px;
          #MapBox {
            margin-top: 0;
            margin-bottom: 10px;
          }
          p {
            margin-bottom: 20px;
            font-size: 16px;
            a.textLink {
              text-decoration: none;
              font-size: 14px;
              }
          }
        }
      }
      #ContBox06 {
        padding-top: 98px;
        background-size: auto 90px;
        h3 {
          img {
            width: 378px;
          }
        }
        ul.blogList {
          padding-top: 4px;
          li {
              a {
                padding:0px;
              }
            dl {
              font-size: 0;
              dt {
                display: inline-block;
                width: 140px;
                padding: 11px 0 14px 0;
                vertical-align:middle;
                span {
                  margin-bottom: 4px;
                  letter-spacing: 0.06em;
                  &:last-of-type {
                    margin-bottom: 0;
                  }
                }
              }
              dd {
                display: inline-block;
                width: 850px;
                padding: 20px 0 24px 0;
                vertical-align:middle;
                font-size: 16px;
                padding-left: 10px;
                p {
                  margin: auto;
                  &:before {
                    top:6px;
                  }
                }
                &:hover {
                  color: #ffa000;
                  transition: 0.3s;
                }
              }
              &:after {
                content: "";
                display: block;
                clear: both;
              }
            }
          }
        }
      }
      #ContBox07 {
        padding-top: 95px;
        background-size: auto 90px;
        padding-bottom: 54px;
        h3 {
          img {
            width: 378px;
          }
        }
        ul.blogList {
          padding-top: 4px;
          li {
            a{
              padding: 0;
            dl {
              dt {
                display: inline-block;
                padding: 11px 0 14px 0;
                vertical-align: middle;
              }
              dd {
                display: inline-block;
                vertical-align: middle;
                width: 760px;
                padding: 20px 0 24px 0;
                &.cate {
                  width: 140px;
                  padding-top:24px;
                  span {
                    margin-bottom: 4px;
                    letter-spacing: 0.06em;
                  }
                }
                p {
                  text-decoration: none;
                  &:before {
                    top:6px;
                  }
                }
                &:hover {
                  p {
                    color: #ffa000;
                    transition: 0.3s;
                  }
                }
              }
            }
          }
        }


        }
      }
    }
  }
}



  /*
  診療案内・アクセス
  -------------------------------------*/
  #PageInfo.pageIndex {
    #MainImg {
      #MainImgInner {
        h2 {
          width: 227px;
          height: 83px;
        }
      }
    }
    #Main {
      .contBox {
        background-image: url(../img/contents/border.png);
        background-repeat: repeat-x;
        background-size: auto 5px;
        background-position: left bottom;
      }
      .contSubBox {
        .moreBox {
          padding-top: 45px;
          background-image: url(../img/contents/bg_cloud.png);
          background-position: center top;
          background-repeat: repeat-x;
          background-size: 375px auto;
          background-color: #fff7db;
          dl {
            margin: 0;
              background-image: url(../img/contents/line_dot.png);
              background-repeat: repeat-x;
              background-position: left bottom;
              background-size: 31px auto;
              &:last-of-type {
                background: none;
              }
            dt {
              margin-bottom: 15px;
              font-weight: bold;
            }
            dd {
              margin-bottom: 20px;
              padding-bottom: 10px;
            }
          }
          img {
            width: 100%;
            border-radius: 10px;
          }
        }
      }
      #ContBox01 {
        padding-top: 45px;
        padding-bottom: 60px;
        h3 {
          text-align: center;
          margin-bottom: 20px;
        }
        iframe {
          width: 100%;
        }
      }
      #ContBox02 {
        .contSubBox {
          padding-bottom: 45px;
          dl {
            margin-bottom: 13px;
            dt {
              margin: 0;
              padding: 0;
            }
            dd {
              margin-top: 0;
              margin-left: 13px;
              padding: 15px 0 30px 37px;
              border-left: #d4df84 solid 1px;
              img {
                width: 100%;
                border-radius: 10px;
              }
              p {
                strong {
                  font-weight: bold;
                  span {
                    color: #f55c70;
                  }
                }
              }
              #TelBox {
                width: 210px;
                height:120px;
                background-image: url(../img/header/tel_wall.png);
                background-position: left top;
                background-size: 141px auto;
                background-repeat: no-repeat;
                a {
                  display: block;
                  width: 210px;
                  height: 120px;
                  padding-top: 70px;
                  text-decoration: none;
                  line-height: 1.2em;
                  .tel {
                  margin: 0 auto;
                  padding-left: 33px;
                  width: 205px;
                  border-bottom: #ffa000 solid 1px;
                  background-image: url(../img/footer/icon_tel.png);
                  background-size: 22px auto;
                  background-repeat: no-repeat;
                  background-position: 4px 2px;
                  letter-spacing: 0.04em;
                  p {
                    margin: 0;
                    font-size: 28px;
                    font-family: 'Abel', sans-serif;
                    font-weight: 400;
                    line-height: 0.9em;
                  }
                }
                span {
                  font-size: 10px;
                  letter-spacing: 0.04em;
                  color: #888;
                }
                }
              }
              &:nth-of-type(1) {
                p {
                  margin-bottom: 5px;
                }
              }
            }
          }
          .etcBox {
            padding: 20px;
            width: 100%;
            background-image: url(../img/contents/wall_02.png);
            background-position: left top;
            background-repeat: repeat;
            background-size: 345px;
            border-radius: 10px;
            h4 {
              margin-bottom: 20px;
              text-align: center;
            }
            ul {
              margin-bottom: 20px;
              li {
                position: relative;
                margin-bottom: 10px;
                padding-left: 27px;
                &:before {
                  content: "";
                  position: absolute;
                  top:8px;
                  left: 3px;
                  width: 15px;
                  height: 12px;
                  background-image: url(../img/contents/arrow_07.png);
                  background-size: 15px auto;
                  background-repeat: no-repeat;
                  background-position: left top;

                }
              }
            }
            img.photo {
              width: 100%;
              border-radius: 10px;
            }
          }
        }
      }
      #ContBox03 {
        .contSubBox {
          padding-top: 10px;
          padding-bottom: 5px;
          table {
            margin-bottom: 10px;
            width: 100%;
            border: #e4d1bf solid 1px;
            border-bottom: none;
            caption {
              height: 46px;
              border: #e4d1bf solid 1px;
              border-bottom: none;
              background-color: #ffa000;
              font-weight: bold;
              font-size: 16px;
              text-align: center;
              line-height: 46px;
              color: #fff;
            }
            tr{
              th,td{
                width: 50%;
                border-bottom: #e4d1bf solid 1px;
              }
              th {
                border-right: #e4d1bf solid 1px;
                background-color: #fff4d6;
              }
              td {
                padding: 8px 10px;
                text-align: center;
                color: #f55c70;
                font-weight: bold;
              }
              &:last-of-type {
                th {
                  font-weight: normal;
                }
                td {
                  font-weight: normal;
                  color: #222;
                }
              }
            }
          }
          ul {
            margin-bottom: 20px;
            li {
              position: relative;
              padding-left: 17px;
              font-size: 14px;
              &:before {
                content: "";
                position: absolute;
                top:8px;
                left:0;
                width: 10px;
                height: 10px;
                background-image: url(../img/contents/arrow_08.png);
                background-size: 10px auto;
                background-repeat: no-repeat;

              }
            }
          }
          dl {
            padding-bottom: 10px;
            dt {
              position: relative;
              padding-left: 40px;
              &:before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                background-repeat: no-repeat;
              }
            }
            dd{
              img.towel {
                float: left;
                margin-right: 15px;
                width: 36%;
                border-radius: 50%;
              }
              &:after {
                content: "";
                display: block;
                clear: both;
              }
            }
            &:nth-of-type(1) {
              dt {
                height: 45px;
                line-height: 52px;
                &:before {
                  left: 3px;
                  width: 24px;
                  height: 45px;
                  background-image: url(../img/contents/information/icon_01.png);
                  background-size: 24px auto;
                }
              }
            }
            &:nth-of-type(2) {
              dt {
                height: 43px;
                line-height: 52px;
                &:before {
                  left: -1px;
                  width: 34px;
                  height: 43px;
                  background-image: url(../img/contents/information/icon_02.png);
                  background-size: 34px auto;
                }
              }
            }
          }
        }
      }
      #ContBox04 {
        .contSubBox {
          padding-bottom: 45px;
          ul {
            margin-bottom: 10px;
            li {
              padding: 12px 0;
              padding-left: 40px;
              position: relative;
              background-image: url(../img/contents/line_dot.png);
              background-repeat: repeat-x;
              background-position: left bottom;
              background-size: 31px auto;
              &:nth-of-type(1) {
                padding-top: 0;
                &:before {
                  content: "";
                  position: absolute;
                  top: -15px;
                  bottom: 0;
                  margin: auto;
                  left: 2px;
                  width: 28px;
                  height: 35px;
                  background-image: url(../img/contents/information/icon_03.png);
                  background-size: 28px auto;
                  background-repeat: no-repeat;
              }
            }
            &:nth-of-type(2) {
                &:before {
                  content: "";
                  position: absolute;
                  top: 0;
                  bottom: 0;
                  margin: auto;
                  left: 0px;
                  width: 30px;
                  height: 35px;
                  background-image: url(../img/contents/information/icon_04.png);
                  background-size: 30px auto;
                  background-repeat: no-repeat;
              }
            }

              &:last-of-type {
                background: none;
                &:before {
                  content: "";
                  position: absolute;
                  top: 0;
                  bottom: 0;
                  margin: auto;
                  left: 2px;
                  width: 26px;
                  height: 35px;
                  background-image: url(../img/contents/information/icon_05.png);
                  background-size: 26px auto;
                  background-repeat: no-repeat;
              }

              }
            }
          }
          img {
            width: 100%;
            border-radius: 10px;
          }
        }
      }
      #ContBox05 {
        .contSubBox {
          padding-bottom: 5px;
          dl {
            margin-bottom: 5px;
            dt {
              font-weight: bold;
            }
           dd{
            a {
              color: #222;
              }
          .mapArea {
            width: 100%;
            border-radius: 10px;
            -webkit-border-radius: 10px;
            overflow: hidden;
            position: relative;
          }
            iframe {
              width: 100%;
            }
            p {
              text-align: right;
              a.textLink {
                color: #ffa000;
                &:hover {
                  text-decoration: none;
                }
              }
            }
            }
          }
          ol {
            list-style: none;
            margin-bottom: 20px;
            li {
              position: relative;
              p {
                margin-bottom: 10px;
              }
              img {
                margin-bottom: 10px;
                width: 100%;
                border-radius: 10px;
              }
              &:nth-of-type(1) {
                div {
                  margin-left: 15px;
                  padding-left: 35px;
                  border-left: #d4df84 solid 1px;
                }
                &:before{
                  content: "";
                  position: absolute;
                  top:0;
                  left: 0;
                  width: 31px;
                  height: 36px;
                  background-image: url(../img/contents/information/icon_06.png);
                  background-size: 31px auto;
                  background-repeat: no-repeat;
                }
              }
              &:nth-of-type(2) {
                padding-left: 50px;
                &:before{
                  content: "";
                  position: absolute;
                  top:0;
                  left: 3px;
                  width: 24px;
                  height: 40px;
                  background-image: url(../img/contents/information/icon_07.png);
                  background-size: 24px auto;
                  background-repeat: no-repeat;
                }
              }
              }
            }
          .moreBox {
            dl {
              dt {
                position: relative;
              }
              dd {
                p {
                  text-align: left;
                }
              }
              &:nth-of-type(1) {
                margin-right: 60px;
                dt {
                  &:before {
                  left: 0px;
                  background-image: url(../img/contents/information/icon_08.png);
                }
                }
              }
              &:nth-of-type(2) {
                dt {
                &:before {
                  left: 0px;
                  background-image: url(../img/contents/information/icon_09.png);
                }
                }
              }
              &:nth-of-type(3) {
                margin-right: 60px;
                dt {
                &:before {
                  left: 0px;
                  background-image: url(../img/contents/information/icon_10.png);
                }
                }
              }
              &:nth-of-type(4) {
                dt {
                &:before {
                  left: 0px;
                  background-image: url(../img/contents/information/icon_11.png);
                }
                }
              }
            }
          }
        }
        margin-bottom: 70px;
      }
    }
  }

@media screen and (max-width: 767px) {
/*##### SP #####*/
  #PageInfo.pageIndex {
    #Main {
      .contBox {
        .linkArea {
          height: 95px;
          cursor: pointer;
          background-image: url(../img/contents/icon_open.png);
          background-repeat: no-repeat;
          background-size: 40px auto;
          background-position: right 15px center;
          text-indent: -9999px;
          h3 {
            margin: 0 auto;
            height: 95px;
            background-position: center center;
            background-repeat: no-repeat;
          }
          &.active {
            background-image: url(../img/contents/icon_close.png);
          }
        }
      }
    #ContBox02 {
      .linkArea {
        h3 {
          width: 120px;
          background-image: url(../img/contents/information/title_02.png);
          background-size: 120px auto;
        }
      }
      .contSubBox{
        .etcBox {
          padding-bottom: 30px;
        }
      }
    }
    #ContBox03 {
      .linkArea {
        h3 {
          width: 196px;
          background-image: url(../img/contents/information/title_03.png);
          background-size: 196px auto;
        }
      }
      .contSubBox {
        table {
          tr {
            th {
              padding: 8px 10px;
            }
            td {
              span {
                font-size: 12px;
              }
            }
          }
        }
        .moreBox {
          dl {
            &:nth-of-type(1) {
              dd {
                margin-bottom: 0;
                padding-bottom: 0;
              }
            }
            &:nth-of-type(2) {
              padding-top: 20px;
              dd {
                p {
                  &:last-of-type {
                    padding-top: 20px;
                  }
                }
              }
            }
          }
        }
      }
    }
    #ContBox04 {
      .linkArea {
        h3 {
          width: 202px;
          background-image: url(../img/contents/information/title_04.png);
          background-size: 202px auto;
        }
      }
    }
    #ContBox05 {
      .linkArea {
        h3 {
          width: 94px;
          background-image: url(../img/contents/information/title_05.png);
          background-size: 94px auto;
        }
      }
      .contSubBox {
        dl {
          margin-bottom: 20px;
          dd {
            .mapArea {
              height: 200px;
              margin-top: 10px;
              margin-bottom: 10px;
              iframe {
                height: 200px;
              }
            }
          }
        }
        .moreBox {
          padding-top: 50px;
          padding-bottom: 15px;
        dl {
          width: 100%;
          dt {
            padding-left: 40px;
            height: 40px;
            line-height: 42px;
            &:before {
                  content: "";
                  position: absolute;
                  top: 0;
                  bottom:0;
                  margin: auto;
                  background-repeat: no-repeat;
            }
          }
          dd {
            .mapArea,iframe {
              height: 200px;
            }
          }
          &:nth-of-type(1) {
            dt {
              &:before {
                width: 32px;
                height: 16px;
                background-size: 32px auto;
              }
            }
          }
          &:nth-of-type(2) {
            dt {
              height: 40px;
              line-height: 42px;
              &:before {
                width: 33px;
                height: 16px;
                background-size: 33px auto;
              }
            }
          }
          &:nth-of-type(3) {
            dt {
              height: 40px;
              line-height: 42px;
              &:before {
                width: 34px;
                height: 28px;
                background-size: 34px auto;
              }
            }
          }
          &:nth-of-type(4) {
            dt {
              height: 40px;
              line-height: 42px;
              &:before {
                width: 31px;
                height: 33px;
                background-size: 31px auto;
              }
            }
          }
        }
      }
      }
    }
    }
  }
}

@media print, screen and (min-width: 768px) {
/*##### PC #####*/
  #PageInfo.pageIndex {
    #MainImg {
      #MainImgInner {
        h2 {
          width: 320px;
          height: 117px;
          padding-top: 15px;
          img {
            width: 320px;
            height: auto;
          }
        }
      }
    }

    #Main {
      .linkArea {
        h3 {
          height: 150px;
          background-repeat: no-repeat;
          background-position: center bottom;
          text-indent: -9999px;
        }
      }
      .showBox {
        display: block;
      }
      .contSubBox {
        .moreBox {
          dl {
            display: inline-block;
            width: 470px;
            background: none;
            vertical-align: top;
            dd {
              margin-bottom: 0;
            }
          }
        }
      }
      #ContBox01 {
        padding-top: 57px;
        .disp_pc {
        padding-top: 75px;
        padding-bottom: 30px;
        }
        #ContSubBox01 {
          display: inline-block;
          width: 470px;
          margin-right: 53px;
          padding-top: 20px;
          vertical-align: top;
          h3 {
            margin-bottom: 22px;
          }
          table.service {
            margin-bottom: 16px;
          }
        }
        #ContSubBox02 {
          display: inline-block;
          width: 470px;
          iframe {
            height: 310px;
          }
        }
        ul {
          text-align: center;
          li {
            display: inline-block;
            margin-right: 25px;
            letter-spacing: 0;
            line-height: 18px;
            a {
              position: relative;
              text-decoration: none;
              color: #333;
              font-size: 14px;
              padding-left: 25px;
              line-height: 18px;
              &:before {
                position: absolute;
                content: "";
                top: 0;
                bottom: 0;
                margin: auto;
                left: 0;
                width: 18px;
                height: 18px;
                background-image: url(../img/contents/information/arrow.png);
                background-size: 18px 18px;
                background-repeat: no-repeat;
              }
              &:hover {
                opacity: 0.6;
                transition: all .3s ease 0s;
              }
            }
            &:nth-of-type(4) {
              margin-right: 0;
            }
          }
        }
      }
      #ContBox02 {
        padding-bottom: 52px;
      .linkArea {
        margin-bottom: 54px;
        h3 {
          background-image: url(../img/contents/information/title_02_pc.png);
          background-size: 166px auto;
        }
      }
        .contSubBox {
          dl {
            dt {
              background-image: url(../img/contents/information/line_dot.png);
              background-repeat: no-repeat;
              background-position: top 12px right 630px;
              img {
                  height: 57px;
                  width: auto;
              }
            }
            dd {
              margin-left: 16px;
              margin-top: -47px;
              padding-left: 352px;
              p {
                line-height: 1.9;
                margin-bottom: 10px;
              }
              #TelBox {
                width: 365px;
                height: 65px;
                margin-top: 10px;
                background-image: url(../img/header/tel_pc.png);
                background-size: 139px auto;
                a {
                  width: 365px;
                  height: 65px;
                  padding-top: 15px;
                  padding-left: 155px;
                  .tel {
                    border: 0;
                    background-position: 0px 2px;
                    padding-left: 28px;
                  }
                }
              }
              &:nth-of-type(1) {
                padding-bottom: 25px;
              }
              &:nth-of-type(5) {
                padding-bottom: 45px;
              }
            }
          }
          .etcBox {
            position: relative;
            padding: 30px;
            background-size: 690px;
            h4 {
              position: absolute;
              top: 75px;
              left: 180px;
              img {
                width: 170px;
              }
            }
            ul {
              width: 470px;
              margin-top: 115px;
              float: left;
            }
            img.photo {
              float: right;
              width: 440px;
            }
            &:after {
              content: "";
              clear: both;
              display: block;
            }
          }
        }
      }
      #ContBox03 {
      .linkArea {
        margin-bottom: 78px;
        h3 {
          background-image: url(../img/contents/information/title_03_pc.png);
          background-size: 261px auto;
        }
      }
        .contSubBox {
          table {
            margin-bottom: 20px;
            tr {
              th {
                padding: 10px 15px 8px;
              }
              td {
                span {
                  font-size: 12px;
                }
              }
            }
          }
          ul {
            margin-bottom: 55px;
          }
          .moreBox {
            padding-top: 90px;
            padding-bottom: 70px;
            background-size: 750px auto;
            .innerBasic {
              font-size: 0;
            }
          dl {
            dt {
              margin-bottom: 20px;
              font-size: 16px;
            }
            dd {
              p {
                line-height: 1.9;
              }
            }
            &:nth-of-type(1) {
              margin-right: 52px;
              dt {
                height: 63px;
                padding-top: 13px;
                padding-left: 53px;
              &:before {
                height: 63px;
                width: 34px;
                background-size:34px auto;
              }
              }
              dd {
                p:nth-of-type(2) {
                  padding-top: 0;
                }
              }
            }
            &:nth-of-type(2) {
              dt {
                height: 60px;
                padding-top: 13px;
                padding-left: 50px;
              &:before {
                top:2px;
                left: -4px;
                height: 60px;
                width: 48px;
                background-size:48px auto;
              }
            }
            }
            dd {
              img.towel {
                width: 100px;
              }
              p:nth-of-type(2) {
                padding-top: 20px;
              }
            }
          }
         }
        }
      }
      #ContBox04 {
      .linkArea {
        margin-bottom: 80px;
        h3 {
          background-image: url(../img/contents/information/title_04_pc.png);
          background-size: 272px auto;
        }
      }
        .contSubBox {
          padding-bottom: 95px;
          ul {
            float: left;
            width: 470px;
            li {
              padding: 25px 0 25px 40px;
              &:nth-of-type(1) {
                padding-top: 25px;
                &:before {
                  top:0;
                }
              }
            }
          }
          img {
            float: right;
            width: 470px;
          }
          &:after {
            content: "";
            clear: both;
            display: block;
          }
        }
      }
      #ContBox05 {
        margin-bottom: 90px;
      .linkArea {
        margin-bottom: 85px;
        h3 {
          background-image: url(../img/contents/information/title_05_pc.png);
          background-size: 144px auto;
        }
      }
      .innerBasic {
            &:after {
              content: "";
              clear: both;
              display: block;
            }
          }
        .contSubBox {
        dl {
          float: left;
          width: 310px;
          dd {
            letter-spacing: 0.02em;
            .mapArea {
              height: 310px;
              margin-bottom: 8px;
            }
            iframe {
              height: 310px;
            }
            p {
              a {
                text-decoration: none;
                &:hover {
                  text-decoration: underline;
                }
              }
            }
            &:nth-of-type(1) {
              margin-bottom: 30px;
            }
          }
        }
        ol {
          float: right;
          width: 630px;
          li {
            margin-bottom: 5px;
            p {
              margin-bottom: 0px;
            }
            div {
              margin-bottom: 15px;
              padding-bottom: 7px;
            }
          }
        }
        .moreBox {
          margin-top: 36px;
          padding-top: 110px;
          padding-bottom: 45px;
          background-size: 750px auto;
          dl {
            width: 470px;
            dt {
              height: 49px;
              padding-left: 50px;
              padding-top: 10px;
              &:before {
                  content: "";
                  position: absolute;
                  top: 0;
                  bottom:0;
                  margin: auto;
                  background-repeat: no-repeat;
              }
            }
            &:nth-of-type(1) {
              dt {
                &:before {
                  top:10px;
                  width: 45px;
                  background-size: 45px auto;
                }
              }
              dd {
                margin-bottom: 5px;
              }
            }
            &:nth-of-type(2) {
              dt {
                &:before {
                  top:10px;
                  width: 46px;
                  background-size: 46px auto;
                }
              }
              dd {
                margin-bottom: 5px;
              }
            }
            &:nth-of-type(3) {
              dt {
                &:before {
                  top:5px;
                  left: -2px;
                  width: 46px;
                  background-size: 46px auto;
                }
              }
            }
            &:nth-of-type(4) {
              dt {
                &:before {
                  left: 0px;
                  width: 45px;
                  background-size: 45px auto;
                }
              }
              dd {
                p {
                  margin-bottom: 15px;
                }
              }
            }
          }
        }
      }
    }
  }
}
}

  /*
  母乳育児の悩みごとあるある
  -------------------------------------*/
  #PageTrouble {
    #MainImg {
      #MainImgInner {
        h2 {
          width: 314px;
          height: 148px;
        }
      }
    }
    #BlogNavi {
      margin-bottom: 70px;
      h2 {
        &:before {
          width: 36px;
          height: 43px;
          background-image: url(../img/contents/trouble/img_02.png);
          background-size: 36px auto;
        }
      }
    }
  }
  #PageTrouble.pageIndex,
  #PageTrouble.pageCategory {
    #Main {
      .contBox{
        padding-bottom: 50px;
        h3 {
          position: relative;
          margin-top: 33px;
          margin-bottom: 15px;
          padding-left: 45px;
          height: 41px;
          font-size: 20px;
          &:before {
            content: "";
            position: absolute;
            top:0px;
            left: 0px;
            width: 35px;
            height: 41px;
            background-repeat: no-repeat;
            background-image: url(../img/contents/trouble/img_01.png);
            background-size: 35px auto;
          }
        }
        ul.blogList {
          margin-bottom: 30px;
          li {
            span {
              background-color: #69afe9;
            }
          }
        }
      }
    }
  }

  /*
  母乳育児の悩みごと記事詳細
  -------------------------------------*/
  #PageTrouble.pageEntry {
    #Main {
      #ContBox01 {
        padding-top: 40px;
        h3 {
          line-height: 1.4;
          font-size: 20px;
          font-weight: bold;
          color: #f55c70;
        }
        .titleBox {
          margin-bottom: 20px;
          span {
            display: inline-block;
            margin-right: 5px;
            padding: 0 6px;
            background-color: #69afe9;
            border-radius: 4px;
            color: #fff;
            font-weight: bold;
            font-size: 12px;
            line-height: 20px;
          }
        }
        .entryBox {
            position: relative;
            margin-bottom: 30px;
            padding: 20px;
            padding-bottom: 90px;
            width: 100%;
            background-image: url(../img/contents/trouble/img_03.png);
            background-position: right 10px bottom 10px;
            background-size: 299px auto;
            background-repeat: no-repeat;
            background-color: #fff7db;
            border-radius: 10px;
            &:before {
              content: "";
              position: absolute;
              top:-10px;
              left: 33px;
              width: 12px;
              height: 12px;
              background-repeat: no-repeat;
              background-image: url(../img/contents/wall_01.png);
              background-size: 12px auto;
            }
        }
      }
    }
  }

  /*
  公式ブログ
  -------------------------------------*/
  #PageBlog {
    #MainImg {
      #MainImgInner {
        h2 {
          width: 315px;
          height: 85px;
        }
      }
    }
    #BlogNavi {
      margin-bottom: 70px;
      h2 {
        height: 49px;
        line-height: 48px;
        margin-bottom: -1px;
        &:before {
          left: 0px;
          width: 34px;
          height: 49px;
          background-image: url(../img/contents/blog/img_02.png);
          background-size: 34px auto;
        }
      }
    }
  }

  #PageBlog.pageIndex,
  #PageBlog.pageCategory {
    #Main {
      .contBox{
        padding-bottom: 50px;
        h3 {
          position: relative;
          margin-top: 33px;
          margin-bottom: 15px;
          padding-left: 45px;
          height: 35px;
          font-size: 20px;
          &:before {
            content: "";
            position: absolute;
            top:0px;
            left: 0px;
            width: 38px;
            height: 35px;
            background-repeat: no-repeat;
            background-image: url(../img/contents/blog/img_01.png);
            background-size: 38px auto;
          }
        }
        ul.blogList {
          margin-bottom: 30px;
          li {
            b {
              margin-right: 10px;
              font-family: 'Abel', sans-serif;
              font-weight: 400;
              font-size: 14px;
              letter-spacing: 0.1em;
              color: #888;
            }
            span {
              background-color: #bcd418;
            }
          }
        }
      }
    }
  }

  /*
  公式ブログカテゴリアーカイブ
  -------------------------------------*/
  #PageBlog.pageCategory {
  }

  /*
  公式ブログ記事詳細
  -------------------------------------*/
  #PageBlog.pageEntry {
    #Main {
      #ContBox01 {
        padding-top: 40px;
        h3 {
          line-height: 1.4;
          font-size: 20px;
          font-weight: bold;
          color: #f55c70;
        }
        .titleBox {
          margin-bottom: 20px;
          span {
            display: inline-block;
            margin-right: 5px;
            padding: 0 6px;
            background-color: #bcd418;
            border-radius: 4px;
            color: #fff;
            font-weight: bold;
            font-size: 12px;
            line-height: 20px;
          }
          b {
              margin-right: 10px;
              font-family: 'Abel', sans-serif;
              font-weight: 400;
              font-size: 14px;
              letter-spacing: 0.1em;
              color: #888;
            }
        }
        .entryBox {
            position: relative;
            margin-bottom: 30px;
            padding: 20px;
            padding-bottom: 100px;
            width: 100%;
            background-image: url(../img/contents/blog/img_03.png),;
            background-position: right 27px bottom 9px;
            background-size: 169px auto;
            background-repeat: no-repeat;
            background-color: #fff7db;
            border-radius: 10px;
            &:before {
              content: "";
              position: absolute;
              top:-10px;
              left: 33px;
              width: 12px;
              height: 12px;
              background-repeat: no-repeat;
              background-image: url(../img/contents/wall_01.png);
              background-size: 12px auto;
            }
        }
      }
    }
  }

@media print, screen and (min-width: 768px) {
/* ###### PC ##### */
  #PageTrouble {
    #MainImg {
      #MainImgInner {
        height: 360px;
        margin-bottom: 24px;
        h2 {
          padding-top: 25px;
          height:224px;
          width: 440px;
          img {
            width: 440px;
            height: 207px;
          }
        }
      }
    }
    #Main {
      float: left;
      width: 690px;
    }
  }
  #PageBlog {
    #MainImg {
      #MainImgInner {
        height: 290px;
        margin-bottom: 24px;
        h2 {
          padding-top: 49px;
          height:170px;
          width: 440px;
          img {
            width: 440px;
          }
        }
      }
    }
    #Main {
      float: left;
      width: 690px;
    }
    #BlogNavi {
      h2 {
        padding-left: 50px;
        &:before {
          left: 12px;
        }
      }
    }
  }
  #PageTrouble.pageIndex,
  #PageTrouble.pageCategory,
  #PageBlog.pageIndex,
  #PageBlog.pageCategory {
    #Container {
      padding-bottom: 40px;
      #Main {
      .contBox {
        ul.blogList {
          li {
            span {
              letter-spacing: 0.08em;
              margin-bottom: 5px;
            }
          }
        }
      }
      }
    }
  }
  #PageTrouble.pageEntry,
  #PageBlog.pageEntry {
    #Main {
      #ContBox01 {
        .entryBox {
          padding: 25px 30px 100px 30px;
          border-radius: 15px;
          background-position: right 20px bottom 10px;
        }
      }
    }
  }

}


/* ###### sp320 ##### */
@media print,
screen and (max-width: 320px) {
  #HeaderMenu_sp {
    width: calc(100% - 20px);
    transform: translate(calc(100% - 20px));
  }
  #Page.pageIndex #Main #ContBox02 #ContSubBox01 .imgArea .textBox p {
    left:8px;
  }
  #PageInfo.pageIndex #Main #ContBox03 .contSubBox dl dt:nth-of-type(2) {
    line-height: 1.4em;
  }
  #PageBlog.pageIndex #Main .contBox h3,
  #PageBlog.pageCategory #Main .contBox h3,
  #PageTrouble.pageIndex #Main .contBox h3,
  #PageTrouble.pageCategory #Main .contBox h3 {
    font-size: 18px;
  }
  #PageTrouble.pageEntry #Main #ContBox01 .entryBox {
        background-size: 250px auto;
  }
  #Main #PageNation {
    width: 290px;
  }
  #Footer #FooterTel a {
    width: 320px;
    background-size: 100px auto;
    background-position: left center;
  }
  #Footer #FooterTel a .telBox {
    right: 5px;
  }


}


/* IE11 にのみ適用される */
@media all and (-ms-high-contrast: none) {
  *::-ms-backdrop,
  #Header #HedCallBox .tel,
  #HeaderMenu_sp #CallBox a .tel {
    letter-spacing:0;
  }
}

@media all and (-ms-high-contrast: none) {
  *::-ms-backdrop,
  #Page.pageIndex #Main .contBox .linkBtn a {
    padding-top:3px;
  }
  #Main ul.blogList li span,
  #PageBlog.pageEntry #Main #ContBox01 .titleBox span,
  #PageTrouble.pageEntry #Main #ContBox01 .titleBox span {
    padding-top:3px;
    line-height: 18px;
  }
  #Main ul.blogList li .showBox .textBox p.linkBtn a {
    padding-top:3px;
  }
  #Main .moreText {
    padding-top:3px;
  }
  #Main #PageNation ul.link_btn li a {
    padding-top:2px;
  }
}